$white: #fff;
$page-auth-bg: $white;
$page-auth-right-bg: linear-gradient(90deg, #2d499d, #3f5491);

#auth {
  height: 100vh;
  overflow-x: hidden;

  #auth-right {
    height: 100%;
    background: url(../../lib/images/bg/4853433.png), $page-auth-right-bg;
  }
  #auth-left {
    max-width: 500px;
    margin: 0 auto;
    padding: 5rem 30px;

    @media (min-width: 1400px) {
      padding: 5rem 3rem;
    }

    .auth-title {
      font-size: 4rem;
      margin-bottom: 1rem;
    }
    .auth-subtitle {
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #a8aebb;
    }
    .auth-logo {
      text-align: center;
      margin-bottom: 30px;

      img {
        height: 200px;
      }
    }
  }
}
