// Import mazer Variables
@import './variables';

// Bootstrap + Icons
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// Fix icon placement
body .bi::before,
[class^='bi-']::before,
[class*=' bi-']::before {
  vertical-align: sub;
}

// Import fonts
@import './fonts';

// Perfect Scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Mazer CSS
@import './mazer';
