@import 'sweetalert2/src/sweetalert2.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import './assets/scss/app';
@import './assets/scss/iconly';

html {
  font-size: 15px;
}

.clickable {
  cursor: pointer;
}

.swal2-styled.swal2-confirm {
  background-color: #435ebe;
}

.text-right {
  text-align: right;
}

a {
  color: #1f487e;

  &:hover {
    color: #3950a2;
  }
}

button.link {
  border: none;
  color: #1f487e;
  background: none;

  &:hover {
    color: #3950a2;
  }
}

.btn-primary {
  background-color: #1f487e;
  border-color: #1f487e;

  &:disabled {
    background-color: #1f487e;
    border-color: #1f487e;
  }
}

.btn-outline-primary {
  border-color: #1f487e;
  color: #1f487e;

  &:hover {
    background-color: #1f487e;
    border-color: #1f487e;
    color: #ffffff;
  }
}

.bg-primary {
  background-color: #1f487e !important;
}

.layout-horizontal {
  .header-top {
    .logo {
      img {
        height: 40px;
      }
    }
  }

  .main-navbar {
    background-color: #1f487e;

    @media (max-width: 1199px) {
      background-color: #f5f7fc;
    }

    ul {
      > .menu-item {
        &.active {
          .menu-link {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.sidebar-wrapper .menu .sidebar-item.active > .sidebar-link {
  background-color: #1f487e;
}

.form-control {
  color: #1f487e;

  &:focus {
    color: #28558e;
  }
}

.nowrap {
  white-space: nowrap;
}

.dropdown .avatar {
  width: 32px;
  height: 32px;
}

.nav-link {
  color: #1f487e;
}

.nav-tabs .nav-link.active {
  color: #28558e;
  background-color: transparent;
}

.nav-tabs .nav-link.active::after {
  background-color: #28558e;
}
