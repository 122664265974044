@font-face {
  font-family: 'Iconly---Bold';
  src: url('../fonts/Iconly---Bold.eot?jilz72');
  src:
    url('../fonts/Iconly---Bold.eot?jilz72#iefix') format('embedded-opentype'),
    url('../fonts/Iconly---Bold.ttf?jilz72') format('truetype'),
    url('../fonts/Iconly---Bold.woff?jilz72') format('woff'),
    url('../fonts/Iconly---Bold.svg?jilz72#Iconly---Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='iconly-bold'],
[class*=' iconly-bold'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Iconly---Bold' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconly-boldActivity:before {
  content: '\e900';
}
.iconly-boldUser:before {
  content: '\e901';
}
.iconly-boldUser1:before {
  content: '\e902';
}
.iconly-boldAdd-User:before {
  content: '\e903';
}
.iconly-boldArrow---Down-2:before {
  content: '\e904';
}
.iconly-boldArrow---Down-3:before {
  content: '\e905';
}
.iconly-boldArrow---Down-Circle:before {
  content: '\e906';
}
.iconly-boldArrow---Down-Square:before {
  content: '\e907';
}
.iconly-boldArrow---Down:before {
  content: '\e908';
}
.iconly-boldArrow---Left-2:before {
  content: '\e909';
}
.iconly-boldArrow---Left-3:before {
  content: '\e90a';
}
.iconly-boldArrow---Left-Circle:before {
  content: '\e90b';
}
.iconly-boldArrow---Left-Square:before {
  content: '\e90c';
}
.iconly-boldArrow---Left:before {
  content: '\e90d';
}
.iconly-boldArrow---Right-2:before {
  content: '\e90e';
}
.iconly-boldArrow---Right-3:before {
  content: '\e90f';
}
.iconly-boldArrow---Right-Circle:before {
  content: '\e910';
}
.iconly-boldArrow---Right-Square:before {
  content: '\e911';
}
.iconly-boldArrow---Right:before {
  content: '\e912';
}
.iconly-boldArrow---Up-2:before {
  content: '\e913';
}
.iconly-boldArrow---Up-3:before {
  content: '\e914';
}
.iconly-boldArrow---Up-Circle:before {
  content: '\e915';
}
.iconly-boldArrow---Up-Square:before {
  content: '\e916';
}
.iconly-boldArrow---Up:before {
  content: '\e917';
}
.iconly-boldBag-2:before {
  content: '\e918';
}
.iconly-boldBag:before {
  content: '\e919';
}
.iconly-boldBookmark:before {
  content: '\e91a';
}
.iconly-boldBuy:before {
  content: '\e91b';
}
.iconly-boldCalendar:before {
  content: '\e91c';
}
.iconly-boldCall-Missed:before {
  content: '\e91d';
}
.iconly-boldCall-Silent:before {
  content: '\e91e';
}
.iconly-boldCall:before {
  content: '\e91f';
}
.iconly-boldCalling:before {
  content: '\e920';
}
.iconly-boldCamera:before {
  content: '\e921';
}
.iconly-boldCategory:before {
  content: '\e922';
}
.iconly-boldChart:before {
  content: '\e923';
}
.iconly-boldChat:before {
  content: '\e924';
}
.iconly-boldClose-Square:before {
  content: '\e925';
}
.iconly-boldDanger:before {
  content: '\e926';
}
.iconly-boldDelete:before {
  content: '\e927';
}
.iconly-boldDiscount:before {
  content: '\e928';
}
.iconly-boldDiscovery:before {
  content: '\e929';
}
.iconly-boldDocument:before {
  content: '\e92a';
}
.iconly-boldDownload:before {
  content: '\e92b';
}
.iconly-boldEdit-Square:before {
  content: '\e92c';
}
.iconly-boldEdit:before {
  content: '\e92d';
}
.iconly-boldFilter-2:before {
  content: '\e92e';
}
.iconly-boldFilter:before {
  content: '\e92f';
}
.iconly-boldFolder:before {
  content: '\e930';
}
.iconly-boldGame:before {
  content: '\e931';
}
.iconly-boldGraph:before {
  content: '\e932';
}
.iconly-boldHeart:before {
  content: '\e933';
}
.iconly-boldHide:before {
  content: '\e934';
}
.iconly-boldHome:before {
  content: '\e935';
}
.iconly-boldImage-2:before {
  content: '\e936';
}
.iconly-boldImage:before {
  content: '\e937';
}
.iconly-boldInfo-Circle:before {
  content: '\e938';
}
.iconly-boldInfo-Square:before {
  content: '\e939';
}
.iconly-boldLocation:before {
  content: '\e93a';
}
.iconly-boldLock:before {
  content: '\e93b';
}
.iconly-boldLogin:before {
  content: '\e93c';
}
.iconly-boldLogout:before {
  content: '\e93d';
}
.iconly-boldMessage:before {
  content: '\e93e';
}
.iconly-boldMore-Circle:before {
  content: '\e93f';
}
.iconly-boldMore-Square:before {
  content: '\e940';
}
.iconly-boldNotification:before {
  content: '\e941';
}
.iconly-boldPaper-Download:before {
  content: '\e942';
}
.iconly-boldPaper-Fail:before {
  content: '\e943';
}
.iconly-boldPaper-Negative:before {
  content: '\e944';
}
.iconly-boldPaper-Plus:before {
  content: '\e945';
}
.iconly-boldPaper-Upload:before {
  content: '\e946';
}
.iconly-boldPaper:before {
  content: '\e947';
}
.iconly-boldPassword:before {
  content: '\e948';
}
.iconly-boldPlay:before {
  content: '\e949';
}
.iconly-boldPlus:before {
  content: '\e94a';
}
.iconly-boldProfile:before {
  content: '\e94b';
}
.iconly-boldScan:before {
  content: '\e94c';
}
.iconly-boldSearch:before {
  content: '\e94d';
}
.iconly-boldSend:before {
  content: '\e94e';
}
.iconly-boldSetting:before {
  content: '\e94f';
}
.iconly-boldShield-Done:before {
  content: '\e950';
}
.iconly-boldShield-Fail:before {
  content: '\e951';
}
.iconly-boldShow:before {
  content: '\e952';
}
.iconly-boldStar:before {
  content: '\e953';
}
.iconly-boldSwap:before {
  content: '\e954';
}
.iconly-boldTick-Square:before {
  content: '\e955';
}
.iconly-boldTicket-Star:before {
  content: '\e956';
}
.iconly-boldTicket:before {
  content: '\e957';
}
.iconly-boldTime-Circle:before {
  content: '\e958';
}
.iconly-boldTime-Square:before {
  content: '\e959';
}
.iconly-boldUnlock:before {
  content: '\e95a';
}
.iconly-boldUpload:before {
  content: '\e95b';
}
.iconly-boldVideo:before {
  content: '\e95c';
}
.iconly-boldVoice-2:before {
  content: '\e95d';
}
.iconly-boldVoice:before {
  content: '\e95e';
}
.iconly-boldVolume-Down:before {
  content: '\e95f';
}
.iconly-boldVolume-Off:before {
  content: '\e960';
}
.iconly-boldVolume-Up:before {
  content: '\e961';
}
.iconly-boldWallet:before {
  content: '\e962';
}
.iconly-boldWork:before {
  content: '\e963';
}
