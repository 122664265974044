@mixin submenu {
  visibility: hidden;
  opacity: 0;
  top: 125%;
  transition: all 0.3s cubic-bezier(0, 0.55, 0.45, 1);
  position: absolute;
  z-index: 999;
  border-radius: 0.2rem;
  background-color: $horizontal-submenu-bg;
  box-shadow: 0px 5px 20px $horizontal-submenu-shadow-color;
}

@mixin submenu-responsive {
  visibility: visible !important;
  opacity: 1;
  box-shadow: none;
  position: static;
  padding: 1rem;
  background-color: unset;
}
