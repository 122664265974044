.layout-navbar {
  --navbar-height: 90px;
  display: block !important;
}
.navbar-fixed > header {
  display: unset;
  height: var(--navbar-height);
}
.navbar-fixed .navbar-top {
  position: sticky;
  background-color: var(--bs-body-bg);
  z-index: 9;
  top: 0;
  width: inherit;
}
.navbar {
  height: var(--navbar-height);
  padding: 1.5rem;
  .navbar-brand img {
    height: 1.5rem;
  }
  .user-menu img {
    width: 39px;
    height: 39px;
  }

  &.navbar-header {
    li {
      display: flex;
      align-items: center;
      &.nav-icon {
        margin-right: 0.4rem;
        .nav-link {
          display: block;
          padding: 0.4rem;
          border-radius: 50%;
          &:hover {
            background-color: $gray-200;
          }
        }
      }
    }
    .dropdown > a {
      color: $gray-600;
      font-weight: 600;

      svg {
        height: 24px;
        width: 24px;
      }
      &:after {
        display: none;
      }
    }
  }
}

.navbar-top {
  .notification-dropdown {
    min-width: 20rem;
  }
  .notification-item {
    margin-bottom: 0.4rem;
    a {
      color: $body-color;
    }
    p {
      margin-bottom: 0;
    }
  }

  .notification-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    text-align: center;
    vertical-align: middle;
    i {
      vertical-align: -moz-middle-with-baseline;
      font-size: 20px;
    }
  }
}

.layout-horizontal {
  .header-top {
    background-color: $horizontal-header-bg;
    padding: 1.1rem;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .burger-btn i {
      height: 20px;
      display: inline-block;
    }

    .logo img {
      height: 20px;
    }
  }
  .header-top-right {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .main-navbar {
    background-color: $horizontal-navbar-bg;
    padding: 1rem;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 2rem;
      margin-bottom: 0;
      .menu-link {
        display: flex;
        position: relative;
        flex-direction: row;
        padding: 0.4rem 0;
        gap: 0.5rem;
        align-items: center;
        span {
          height: 20px;

          & > i {
            margin-right: 3px;
          }
        }
      }
      & > .menu-item {
        position: relative;
        .menu-link {
          color: $gray-300;
        }
        &.has-sub .menu-link {
          padding-right: 1.3rem;
          &:after {
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="%23ccc" opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" ></polyline></svg>');
            position: absolute;
            color: #fff;
            right: -3px;
            top: 7px;
            display: block;
          }
        }
        &:hover {
          .menu-link {
            color: white;
          }
          .submenu {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
      }
    }
    .submenu {
      @include submenu;

      .submenu-group-wrapper {
        position: relative;
      }

      .submenu-group {
        display: table-cell;
        padding: 0.5rem 0.3rem 0.3rem 0.5rem;
        flex-wrap: wrap;
        max-height: 200px;
        min-width: 200px;

        .submenu-item {
          position: relative;
          &.has-sub .submenu-link {
            position: relative;
            &:after {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-40%);
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23888' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
            }
          }
          a {
            padding: 0.6rem;
            padding-right: 2rem;
            color: $horizontal-submenu-link-color;
            display: block;
            &:hover {
              color: $horizontal-submenu-link-hover-color;
            }
          }
          &:hover {
            .subsubmenu {
              visibility: visible;
              top: 0rem;
              opacity: 1;
            }
          }
        }
      }
    }
    .subsubmenu {
      @include submenu;
      gap: 0;
      top: 1rem;
      left: 100%;
      display: flex;
      padding: 0.5rem;
      flex-direction: column;
      border-radius: 0.3rem;
      min-width: 200px;
    }

    @media screen and (max-width: 1199px) {
      background-color: lighten($primary, 47%);
      overflow: hidden;
      padding: 1rem;
      display: none;

      &.active {
        max-height: initial;
      }
      ul {
        flex-direction: column;
        gap: 0;

        .menu-item {
          &.has-sub .menu-link:after {
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="%23888" opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" ></polyline></svg>') !important;
            top: unset;
          }
        }
        .menu-link {
          color: $gray-600 !important;
          padding: 1rem 0;
        }
      }
      .submenu {
        @include submenu-responsive;
        transition: all 0.2s;
        padding-top: 0;
        display: none;
        padding-bottom: 0;
        .submenu-group-wrapper {
          display: flex;
          flex-direction: column;
          max-height: unset;
        }
        .submenu-group {
          max-height: unset;
          padding: 0;
          min-width: unset;
          width: 100%;
        }
        &.active {
          display: block;
        }
      }
      .subsubmenu {
        @include submenu-responsive;
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
}
#topbarUserDropdown:after {
  margin-left: 1rem;
}
