.breadcrumb {
  &.breadcrumb-right {
    justify-content: flex-end;
    margin-top: $breadcrumb-margin-top;
  }
  &.breadcrumb-center {
    justify-content: center;
    margin-top: $breadcrumb-margin-top;
  }
}
