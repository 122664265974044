.alert {
  border: none;
  .alert-heading {
  }
  p {
    margin-bottom: 0;
  }
  button.close {
    padding: 0.75rem;
    line-height: 0.75;
  }
  .alert-heading {
    margin-left: 0.4rem;
    & + p {
      margin-left: 0.4rem;
    }
  }
}
@each $key, $value in $alert-colors {
  .alert-#{$key} {
    background-color: map-get($value, 'background-color');
    color: map-get($value, 'text-color');

    a {
      color: #fff;
      font-weight: bold;
    }
  }
}
@each $key, $value in $theme-colors-light {
  .alert-light-#{$key} {
    @if ($dark-mode == true) {
      background-color: lighten(saturate($value, 30%), 10%);
      color: saturate(shade-color($value, 60%), 40%);
      border: 1px solid tint-color($value, 10%);
    } @else {
      background-color: saturate($value, 10%);
      color: saturate(shade-color($value, 60%), 40%);
    }
  }
}
