@use 'sass:map';

.btn {
  i,
  svg {
    width: 1rem;
    height: 1rem;
  }
  &.icon {
    padding: 0.4rem 0.6rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
    &.icon-left {
      svg {
        margin-right: 3px;
      }
    }
    &.icon-right {
      svg {
        margin-left: 3px;
      }
    }
  }
  &.btn-outline-white {
    color: #fff;
    border-color: #fff;
    &:hover {
      color: #333;
      background-color: #fff;
    }
  }

  @each $key, $value in $theme-colors-light {
    &.btn-light-#{$key} {
      background-color: $value;
      color: darken($value, 80%);
    }
  }

  @each $key, $value in $btn-colors {
    &.btn-#{$key} {
      color: map.get($btn-colors, 'key', 'text-color');
    }
  }
}
.btn-block {
  width: 100%;
}
.btn-group {
  &:not(.dropdown) .btn:not([class*='btn-']) {
    border: 1px solid #dfe3e7;
  }
  & > .btn {
    border-radius: 0.267rem;
  }
}
.buttons {
  .btn {
    margin: 0 10px 10px 0;
  }
}
